.mobile-not-support {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #3c965a;
}

.mobile-not-support h1 {
    color: darkgrey;
}

button {
    border-radius: 4px;
}
